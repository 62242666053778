import { alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
}

const PRIMARY = {
  lighter: '#E0FFFF',
  light: '#C6FFFF',
  main: '#60EDEB',
  dark: '#51DEDC',
  darker: '#49D6D4',
  contrastText: '#FFFFFF',
}

const SECONDARY = {
  lighter: '#B8C2C4',
  light: '#6C7678',
  main: '#1F292B',
  dark: '#172123',
  darker: '#101A1C',
  contrastText: '#FFFFFF',
}

const INFO = {
  lighter: '#8BB0FF',
  light: '#5289FF',
  main: '#1861FF',
  dark: '#1249BF',
  darker: '#0C3180',
  contrastText: '#FFFFFF',
}

const SUCCESS = {
  lighter: '#8DD8AC',
  light: '#53C483',
  main: '#42CE00',
  dark: '#148543',
  darker: '#0D582C',
  contrastText: GREY[800],
}

const WARNING = {
  lighter: '#FFE083',
  light: '#FFD145',
  main: '#FFC107',
  dark: '#BF9105',
  darker: '#806103',
  contrastText: GREY[800],
}

const ERROR = {
  lighter: '#FF8F8F',
  light: '#FF5656',
  main: '#FF1E1E',
  dark: '#BF1616',
  darker: '#800F0F',
  contrastText: '#FFFFFF',
}

const palette = {
  mode: 'light',
  common: {
    black: '#000000',
    white: '#FFFFFF',
    grey: '#8B8B8B',
    greyLight: '#B3B3B3',
    light: '#FFF2E3',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: '#FFF',
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}

export default palette
