import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet-async'

const Loader = () => {
  return (
    <>
      <Helmet>
        <title>Home | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress color="secondary" size={45} />
      </Box>
    </>
  )
}

export default Loader
