import PropTypes from 'prop-types'
import { useMemo } from 'react'
// @mui
import { CssBaseline, responsiveFontSizes } from '@mui/material'
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles'
//
import palette from './palette'
import shadows from './shadows'
import typography from './typography'
import GlobalStyles from './globalStyles'
import customShadows from './customShadows'

// ----------------------------------------------------------------------

const ThemeProvider = (props) => {
  const { children } = props

  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    [],
  )

  const theme = createTheme(themeOptions)
  theme.components = {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          boxShadow: '0px 0px 60.9px 0px rgba(0, 0, 0, 0.10)',
          [theme.breakpoints.up('md')]: {
            padding: '25px',
          },
          [theme.breakpoints.down('md')]: {
            padding: '25px 0px',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#000',
          fontWeight: 500,
          fontSize: '14px',
          whiteSpace: 'wrap !important',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: '13.5px !important',
        },
        root: {
          backgroundColor: '#FFFFFF',
          borderRadius: '12px !important',
        },
        inputMultiline: {
          padding: '0px !important',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        filled: {
          height: '27px',
          fontSize: '14px',
          fontWeight: 500,
          color: '#FFFFFF',
          backgroundColor: '#1F292B',
          borderRadius: '6px',
        },
        label: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        input: {
          padding: '0px !important',
        },
        select: {
          paddingTop: '5px !important',
          paddingBottom: '5px !important',
          paddingLeft: '8px !important',
          paddingRight: '24px !important',
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              top: 'calc(50% - .5em)',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          borderRadius: '12px',
          fontSize: '16px',
          fontWeight: 400,
          borderWidth: '0.8px',
        },
        notchedOutline: {
          border: '1px solid #E6E6E6',
          backgroundColor: 'rgba(159, 158, 150, 0.08)',
          borderRadius: '12px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        standard: {
          borderRadius: '12px 0 0 12px',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 15,
        },
        icon: {
          top: 'unset',
          marginRight: 4,
        },
        // icon: {
        //   top: '30%',
        //   marginRight: '6px',
        // },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            WebkitTextFillColor: 'unset',
          },
          '&.Mui-active': {
            color: 'white',
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          '.MuiIconButton-edgeEnd': {
            backgroundColor: '#FFFFFF',
            borderRadius: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '12px',
          padding: '15px 18px',
          // maxWidth: '410px',
        },
        startIcon: {
          [theme.breakpoints.down('md')]: {
            margin: '0',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: '4px !important',
        },
        popupIndicator: {
          marginRight: '2px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 500,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '10px',
          border: '0.5px solid #011B3614',
          boxShadow: '0px 0px 10px 3px #0000000D',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: '1.5px solid #60EDEB99',
          boxShadow: '0px 0px 60.900001525878906px 0px #0000001A',
          borderRadius: '20px',
          paddingBottom: '10px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingLeft: '22px',
          paddingRight: '22px',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          fontSize: '14px',
          paddingRight: '42px',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          opacity: 1,
        },
        page: {
          border: '1.5px solid #1F292B',
          color: '#1F292B',
          fontWeight: 500,
          '&.Mui-selected': {
            backgroundColor: '#1F292B',
            color: '#FFF',
            ':hover': {
              backgroundColor: '#1F292B',
              color: '#FFF',
            },
          },
          ':hover': {
            backgroundColor: '#1F292B',
            color: '#FFF',
          },
        },
        ellipsis: {
          color: '#1F292B',
        },
        previousNext: {
          border: '1.5px solid #1F292B',
          backgroundColor: '#FFF !important',
          color: '#1F292B',
          ':hover': {
            backgroundColor: '#FFF',
            color: '#1F292B',
          },
        },
        icon: {
          color: '#1F292B',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          zIndex: 1000,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#60EDEB',
          color: '#000',
          border: '1.5px solid #60EDEB99',
          boxShadow: '0px 0px 60.900001525878906px 0px #0000001A',
          marginTop: '0px !important',
        },
        arrow: {
          color: '#60EDEB',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '32px',
          height: '32px',
          backgroundColor: '#1F292B',
        },
      },
    },
  }

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={responsiveFontSizes(theme)}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
}

export default ThemeProvider
