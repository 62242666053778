import { lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { retry } from '../common/utils/CommonFunctions'
import { useAuthContext } from '../hooks/useAuthContext'

const Sidebar = lazy(() => retry(() => import('../layout/Sidebar')))
const Login = lazy(() => retry(() => import('../pages/Login')))
const FourOhFourHome = lazy(() =>
  retry(() => import('../pages/FourOhFourHome')),
)

const Routes = () => {
  const { token } = useAuthContext()

  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate replace to="/users" />,
    },
    {
      path: '/login',
      element: !token ? <Login /> : <Navigate replace to="/users" />,
    },
    {
      path: '/*',
      element: token ? <Sidebar /> : <Navigate replace to="/login" />,
    },
    {
      path: '/*',
      element: <Navigate replace to="/404" />,
    },
    {
      path: '/404',
      element: <FourOhFourHome />,
    },
  ])

  return routes
}

export default Routes
